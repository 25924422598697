<template>
  <el-container>
    <Header pageName="应用管理"></Header>
    <el-main>
      <el-form class="el-form-search" label-width="120px">
        <el-form-item label="店铺名称：">
          <el-input size="small" v-model="searchItem.keywords" placeholder="请输入店铺名称"></el-input>
        </el-form-item>
        <el-form-item label="注册日期：">
          <el-date-picker v-model="searchItem.create_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
        </el-form-item>
        <el-form-item label="上次登录日期：">
          <el-date-picker v-model="searchItem.last_login_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
        </el-form-item>
        <el-form-item label=" " label-width="30px">
          <el-button type="primary" size="small" @click="get_data_list(1)">搜索</el-button>
          <el-button size="small">导出</el-button>
          <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-tabs v-model="searchItem.status" type="card" @tab-click="get_data_list(1)">
        <el-tab-pane v-for="(item, index) in status_list" :key="index" :label="item.name + '(' + item.nums + ')'" :name="item.id"></el-tab-pane>
      </el-tabs>
      <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="name" label="店铺" align="center"></el-table-column>
        <el-table-column label="来源" align="center">
          <template v-slot="{ row }">
            {{ row.from ? '渠道' : '线上' }}
          </template>
        </el-table-column>
        <el-table-column prop="u_account" label="总营业额" align="center">
          <template v-slot="{ row }">￥{{ row.amount }}</template>
        </el-table-column>
        <el-table-column label="总用户数" align="center">
          <template v-slot="{ row }">{{ row.total_user }}</template>
        </el-table-column>
        <el-table-column label="总浏览量" align="center">
          <template v-slot="{ row }">{{ row.total_visit }}</template>
        </el-table-column>
        <el-table-column label="注册时间" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
        <el-table-column label="距期限" align="center">
          <template v-slot="{ row }">{{ row.distance_period }}天</template>
        </el-table-column>
        <el-table-column label="上次登录时间" align="center">
          <template v-slot="{ row }">{{ row.last_login_time ? getDateformat(row.last_login_time) : '--' }}</template>
        </el-table-column>
        <el-table-column label="店铺状态" align="center">
          <template v-slot="{ row }">{{ row.deleted ? '回收站' : getItemStatus(row.status) }}</template>
        </el-table-column>
        <el-table-column label="上线应用" align="center">
          <template v-slot="{ row }">{{ getUpApp(row) }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <el-button type="text" @click="VirtualFunction(row)">{{ row.is_virtual ? '关闭虚拟量' : '开启虚拟量' }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="total" :page="page" :pageNum="pagesize" @updatePageNum="updateData" :isMin="1"></Paging>
    </el-main>
  </el-container>
</template>

<script>
import Header from './components/header';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate.js';
export default {
  components: {
    Header,
    Paging,
  },
  data() {
    return {
      getDateformat,
      searchItem: {
        keywords: '',
        create_time: [],
        last_login_time: [],
        status: -1,
      },
      page: 1,
      pagesize: 5,
      total: 0,
      status_list: [
        { id: -1, name: '全部', nums: 0 },
        { id: 1, name: '正常', nums: 0 },
        { id: 2, name: '试用', nums: 0 },
        { id: 3, name: '一月内过期', nums: 0 },
        { id: 4, name: '已过期', nums: 0 },
      ],
      dataList: [],
    };
  },
  created() {
    this.get_data_list();
    this.getStatusNum();
  },
  methods: {
    // 获取应用已上线的平台
    getUpApp(val) {
      let str = '';
      if (val.is_ali) {
        str += '支付宝 ';
      }
      if (val.is_baidu) {
        str += '百度 ';
      }
      if (val.is_fastapp) {
        str += '快应用 ';
      }
      if (val.is_wechat) {
        str += '微信 ';
      }
      return str ? str : '--';
    },
    // 获取应用状态
    getItemStatus(status) {
      let arr = [
        { id: 1, name: '正常' },
        { id: 2, name: '试用期' },
        { id: 4, name: '已过期' },
      ];
      let data = arr.find(item => item.id == status);
      return data.name;
    },
    get_data_list(style) {
      if (style) {
        this.page = 1;
      }
      let obj = {
        page: this.page,
        pagesize: this.pagesize,
      };
      if (this.searchItem.keywords) {
        obj.keywords = this.searchItem.keywords;
      }
      if (this.searchItem.status !== -1) {
        obj.status = this.searchItem.status;
      }
      if (this.searchItem.create_time && this.searchItem.create_time.length) {
        obj.create_time = [];
        obj.create_time[0] = Math.floor(this.searchItem.create_time[0] / 1000);
        obj.create_time[1] = Math.floor(this.searchItem.create_time[1] / 1000);
        if (obj.create_time[0] === obj.create_time[1]) {
          obj.create_time[1] = obj.create_time[0] + 60 * 60 * 24 - 1;
        }
      }
      if (this.searchItem.last_login_time && this.searchItem.last_login_time.length) {
        obj.last_login_time = [];
        obj.last_login_time[0] = Math.floor(this.searchItem.last_login_time[0] / 1000);
        obj.last_login_time[1] = Math.floor(this.searchItem.last_login_time[1] / 1000);
        if (obj.last_login_time[0] === obj.last_login_time[1]) {
          obj.last_login_time[1] = obj.last_login_time[0] + 60 * 60 * 24 - 1;
        }
      }
      this.$axios.post(this.$api.admin.ApplicationManagement.list, obj).then(res => {
        if (res.code == 0) {
          this.dataList = res.result.account_list;
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    updateData(val, status) {
      if (status == 0) {
        this.pagesize = val;
      } else {
        this.page = val;
      }
      this.get_data_list();
    },
    cancelSearch() {
      this.searchItem = {
        keywords: '',
        create_time: [],
        last_login_time: [],
        status: -1,
      };
      this.get_data_list();
    },
    getStatusNum() {
      this.$axios.post(this.$api.admin.ApplicationManagement.listCount).then(res => {
        if (res.code == 0) {
          this.status_list[0].nums = res.result.total_number; // 全部
          this.status_list[1].nums = res.result.normal_number; // 正常
          this.status_list[2].nums = res.result.try_number; // 试用
          this.status_list[3].nums = res.result.month_number; // 一月内过期
          this.status_list[4].nums = res.result.expire_number; // 已过期
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    VirtualFunction(row) {
      let str = row.is_virtual ? '请确认是否要关闭虚拟量？' : '请确认是否要开启虚拟量？';
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.admin.ApplicationManagement.openVirtual, {
            uniacid: row.uniacid,
            is_virtual: row.is_virtual ? 0 : 1,
          });
        })
        .then(res => {
          if (res.code === 0) {
            let resStr = row.is_virtual ? '已成功关闭虚拟量' : '已成功开启虚拟量';
            row.is_virtual = row.is_virtual ? 0 : 1;
            this.$message.success(resStr);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
  }
}
</style>
